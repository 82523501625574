export enum ERole {
  admin = 'Администратор',
  owner = 'Владелец',
  hr = 'HR',
}

export type TRole = keyof typeof ERole;

export enum EUserStatus {
  ACTIVE = 'active',
  BANNED = 'banned',
}

export enum EOrderStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

export enum ETransactionDetails {
  TRANSACTION_DETAILS_ORDER_NEW = 'TRANSACTION_DETAILS_ORDER_NEW',
  TRANSACTION_DETAILS_ORDER_REFUND = 'TRANSACTION_DETAILS_ORDER_REFUND',
  PLANNING_REPLENISHMENT = 'PLANNING_REPLENISHMENT',
  MONTHLY_BURNING = 'MONTHLY_BURNING',
  EVERY_YEAR_PAYMENTS = 'EVERY_YEAR_PAYMENTS',
  CASHBACK = 'CASHBACK',
}

export enum ECurrency {
  COINS = 'coins',
  DIAMONDS = 'diamonds',
  THANKS = 'thanks',
}

export enum ETransactionType {
  income = 'Начисление',
  expense = 'Списание',
}

export enum ETransactionErrorMessage {
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
}

export type TErrorMessages = ETransactionErrorMessage;
