import { FC, useEffect, useMemo, useState } from 'react';

import { Form, Input, Modal, Select } from 'antd';
import { IAdminUser } from 'api/types/admin-users';
import { useSubmit } from 'hooks/useSubmit';
import { useApp } from 'lib/app';

interface AdminCreateFormProps {
  open: boolean;
  onCancel: () => void;
}

const AVAILABLE_ROLES = [
  {
    label: 'Администратор',
    value: 'admin',
  },
  {
    label: 'HR',
    value: 'hr',
  },
];

export const AdminCreateForm: FC<AdminCreateFormProps> = ({
  open,
  onCancel,
}) => {
  const { auth } = useApp();
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();
  // Watch all valuess
  const values = Form.useWatch([], form);

  const { submit } = useSubmit(() => {
    onCancel();
  });

  const handleCreate = (values: Pick<IAdminUser, 'email' | 'role'>) => {
    submit({
      entity: values.role === 'hr' ? 'createHr' : 'createAdmin',
      data: { ...values },
      queryKeys: ['admins'],
    });
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch(() => {
        setSubmittable(false);
      });
  }, [form, values, open]);

  const initialValues = useMemo(
    () => ({
      role: auth.user?.role === 'admin' ? 'hr' : 'admin',
    }),
    [auth.user?.role]
  );

  return (
    <Modal
      open={open}
      centered
      title="Создание нового администратора"
      okText="Создать"
      cancelText="Отменить"
      onCancel={onCancel}
      okButtonProps={{ disabled: !submittable }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="admin-create"
        initialValues={initialValues}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите почту',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role">
          <Select
            options={AVAILABLE_ROLES}
            defaultValue="hr"
            disabled={auth.user?.role === 'admin'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
