import { PropsWithChildren, ReactNode } from 'react';

import cn from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { reorderQueue } from 'utils';

import styles from './draggable-item.module.scss';

interface IItem {
  order: number;
  categoryId?: number | string;
}

interface DraggableItemProps<T> {
  item: T;
  collection: T[];
  children: ReactNode;
  onDropAction: (reorderedCollection: T[]) => void;
  allowDropOn?: (a: number, b: number) => boolean;
}

export const DraggableItem = <T extends IItem>({
  children,
  item,
  collection,
  onDropAction,
  allowDropOn = () => true,
}: PropsWithChildren<DraggableItemProps<T>>) => {
  const [{ isOver, isAllowedDrop }, drop] = useDrop({
    accept: 'drop',
    drop: ({ movedIndex }: any) => {
      if (!isAllowedDrop) return;

      const activeItemIndex = collection.findIndex(
        (obj) => obj.order === movedIndex
      );

      const overItemIndex = collection.findIndex(
        (obj) => obj.order === item.order
      );

      const reorderedCollection = reorderQueue(
        collection,
        activeItemIndex,
        overItemIndex
      );

      onDropAction(reorderedCollection);
    },
    collect: (monitor) => {
      const draggedItem = monitor.getItem();

      if (draggedItem) {
        const activeItemIndex = collection.findIndex(
          (obj) => obj.order === draggedItem.movedIndex
        );

        const overItemIndex = collection.findIndex(
          (obj) => obj.order === item.order
        );

        const isAllowedDrop = allowDropOn(activeItemIndex, overItemIndex);

        return {
          isOver: monitor.isOver(),
          isAllowedDrop,
        };
      }

      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      name: 'droduct dnd',
      type: 'drop',
      item: { movedIndex: item.order },

      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [item.order]
  );

  return (
    <span
      className={cn(styles.draggable, {
        [styles.isOver]: isOver && (isAllowedDrop ?? true),
      })}
      ref={(node) => {
        dragRef(node);
        drop(node);
      }}
      style={{
        opacity,
      }}
    >
      {children}
    </span>
  );
};
