import {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
  FC,
} from 'react';
import { redirect } from 'react-router-dom';

import { getAuth } from 'api/auth';
import { $host } from 'api/index';
import { IAdminUser } from 'api/types/admin-users';
import { ID } from 'api/types/common';
import { ERole, TRole } from 'api/types/enums';
import { AxiosError } from 'axios';

interface AuthProviderProps {
  children?: ReactNode;
}

export interface IAuth {
  user: SystemUser | null;
  loading: boolean;
  token: string | null;
}

interface AppContextDefaultValue {
  loading: boolean;
  auth: IAuth;
}

const AppContext = createContext<AppContextDefaultValue>({
  loading: true,
  auth: {
    user: null,
    loading: true,
    token: localStorage.getItem('token') || null,
  },
});

export const AppProvider: FC<AuthProviderProps> = ({ children }) => {
  const app = useProvideAuth();

  if (app.auth.loading) return null;

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);

interface SystemUser extends IAdminUser {
  is: (role: ERole) => boolean;
}

class AdminUser implements SystemUser {
  googleId: string;
  id: ID;
  email: string;
  role: TRole;
  createdAt: string;

  constructor(userData: IAdminUser) {
    this.googleId = userData.googleId;
    this.id = userData.id;
    this.email = userData.email;
    this.createdAt = userData.createdAt;
    this.role = userData.role;
  }

  is(role: ERole) {
    const userRole = Object.entries(ERole).find((roleEntries) =>
      roleEntries.some((entry) => entry === role)
    )?.[0];

    if (!userRole) {
      throw Error('Role is not supported');
    }

    return this.role === userRole;
  }
}

function useProvideAuth() {
  const [token, setToken] = useState<string | null>(() =>
    localStorage.getItem('token')
  );

  const [user, setUser] = useState<SystemUser | null>(null);
  const [appLoading, setAppLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchToken = new URLSearchParams(document.location.search).get(
      'token'
    );

    if (!searchToken && !token) {
      setLoading(false);
      setAppLoading(false);

      return;
    }

    const targetToken = searchToken || token || '';

    const getAdmin = async () => {
      try {
        setLoading(true);

        const {
          data: { data },
        } = await getAuth(targetToken);

        localStorage.setItem('token', targetToken);

        $host.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${targetToken}`;

        setToken(targetToken);
        setUser(new AdminUser(data));
        redirect('/');
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401) {
            window.location.href = '/login';

            return;
          }

          throw new Error(e.message);
        }
      } finally {
        setLoading(false);
      }
    };

    getAdmin();
    setAppLoading(false);
  }, [token]);

  return {
    loading: appLoading,
    auth: {
      user,
      loading,
      token,
    },
  };
}
