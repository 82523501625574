import { ReactNode } from 'react';

import { ERole } from 'api/types/enums';

import { useApp } from './app';

export interface IRoleWrapperProps {
  excludedRoles: ERole[];
  children: ReactNode | (() => ReactNode);
}

export const RoleWrapper = ({
  excludedRoles,
  children,
}: IRoleWrapperProps): ReactNode => {
  const { auth } = useApp();

  const shouldRender = !excludedRoles.some((role) => auth.user?.is(role));

  if (shouldRender) {
    return typeof children === 'function' ? children() : children;
  } else {
    return null;
  }
};

export const useRouteWrapper = <T>(excludedRoles: ERole[], object: T) => {
  const { auth } = useApp();

  const shouldRender = !excludedRoles.some((role) => auth.user?.is(role));

  if (shouldRender) {
    return object;
  } else {
    return null;
  }
};
