import {
  deleteAdminUser,
  deleteHrUser,
  postAdminUser,
  postHrUser,
} from 'api/admin-users';
import {
  TCreateAdminUserPayload,
  TDeleteAdminUserPayload,
} from 'api/types/admin-users';

export const actions = {
  createAdmin: async (data: TCreateAdminUserPayload) => {
    await postAdminUser(data);
  },
  createHr: async (data: TCreateAdminUserPayload) => {
    await postHrUser(data);
  },
  deleteAdmin: async (data: TDeleteAdminUserPayload) => {
    await deleteAdminUser(data);
  },
  deleteHr: async (data: TDeleteAdminUserPayload) => {
    await deleteHrUser(data);
  },
};
