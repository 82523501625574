import { FC, useState } from 'react';

import { Button, Card, Popconfirm } from 'antd';
import { ID } from 'api/types/common';
import { EUserStatus } from 'api/types/enums';
import { IUser } from 'api/types/user';
import { DataTable } from 'components/data-table';
import { UserCreateForm, IUserForm } from 'components/forms/user/user-create';
import { useSubmit } from 'hooks/useSubmit';

import { tableRows } from './rows';

import styles from '../user.module.scss';

interface IPersonalInformationProps {
  user: IUser;
}

export const PersonalInformation: FC<IPersonalInformationProps> = ({
  user,
}) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<IUser | null>(null);

  const { submit } = useSubmit(() => {
    setOpen(false);
  });

  const handleSubmit = (
    { telegramId, ...values }: IUserForm,
    entity: string,
    id?: ID
  ) => {
    submit({
      entity: entity,
      data: {
        values: {
          ...values,
          telegramId: telegramId ? telegramId : null,
        },
        id,
      },
      queryKeys: [`user/${user.id}`],
    });

    if (editValue) {
      setEditValue(null);
    }
  };

  const handleBan = () => {
    submit({
      entity: 'banUser',
      data: { id: user.id },
      queryKeys: ['users', `user/${user.id}`],
    });
  };

  const handleUnban = () => {
    submit({
      entity: 'updateUser',
      data: { values: { status: 'active' }, id: user.id },
      queryKeys: ['users', `user/${user.id}`],
    });
  };

  const handleUserEdit = () => {
    setOpen(true);
    setEditValue(user);
  };

  return (
    <>
      <Card
        className={styles.content}
        title="Персональная информация"
        actions={[
          <div className={styles.actions}>
            <Button
              className={styles.actions__button}
              size="small"
              onClick={handleUserEdit}
              type="primary"
            >
              Редактировать
            </Button>
            <Popconfirm
              cancelText="Отменить"
              title={
                user.status === EUserStatus.BANNED
                  ? 'Вы уверенны, что хотите разблокировать пользователя?'
                  : 'Вы уверенны, что хотите заблокировать пользователя?'
              }
              onConfirm={() => {
                user.status === EUserStatus.BANNED
                  ? handleUnban()
                  : handleBan();
              }}
            >
              <Button
                className={styles.actions__button}
                size="small"
                type="link"
              >
                {user.status === EUserStatus.BANNED
                  ? 'Разблокировать'
                  : 'Заблокировать'}
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <DataTable rows={tableRows} value={user} />
      </Card>
      <UserCreateForm
        open={open}
        initialValue={editValue}
        onSubmit={handleSubmit}
        onCancel={() => {
          setOpen(false);
          setEditValue(null);
        }}
      />
    </>
  );
};
