import { currencyIcon } from 'constants/currencies';

import { HTMLAttributes, useMemo, useRef, useState, useCallback } from 'react';
import { useLoaderData } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
  Image,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ICategory } from 'api/types/category';
import { ID, IPagginatedDataResponse } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { IProduct } from 'api/types/products';
import { DraggableItem } from 'components/draggable-item';
import { ProductCreateForm } from 'components/forms/product/product-create';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import styles from './shop.module.scss';

const { Text } = Typography;

export const ShopPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [productCreateFormIsOpen, setProductCreateFormIsOpen] = useState(false);
  const [editValue, setEditValue] = useState<IProduct | null>(null);
  const [imagePreviewOpen, setImagePreviewOpen] = useState<ID>('');

  const { products, categories, currencies } = useLoaderData() as {
    products: IPagginatedDataResponse<IProduct[]>;
    categories: IPagginatedDataResponse<ICategory[]>;
    currencies: ICurrency[];
  };

  const { submit } = useSubmit(() => {
    setProductCreateFormIsOpen(false);
  });

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const handleSubmit = (values: any, entity: string, id?: ID) => {
    submit({
      entity: entity,
      data: { values: values, id },
      queryKeys: ['products'],
    });

    if (editValue) {
      setEditValue(null);
    }
  };

  const sortedProducts = useMemo(
    () =>
      [...products.result].sort((a, b) => {
        if (!a.category) return 1;

        if (!b.category) return -1;

        return a.category.order - b.category.order;
      }),
    [products]
  );

  const handleEdit = (data: IProduct) => {
    setProductCreateFormIsOpen(true);
    setEditValue(data);
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'deleteProduct',
      data: { id },
      queryKeys: ['products'],
    });
  };

  const handleReorder = (reorderedCollection: any[]) => {
    submit({
      entity: 'reorderProduct',
      data: reorderedCollection,
      queryKeys: ['products', 'categories'],
    });
  };

  const dropOnSameCategory = useCallback(
    (a: number, b: number) => {
      if (products.result[a].categoryId === products.result[b].categoryId) {
        return true;
      }

      return false;
    },
    [products]
  );

  const columns: ColumnsType<IProduct> = [
    {
      title: 'Категория',
      dataIndex: 'category',
      key: 'category',
      width: 150,
      render: (data, record, index) => {
        const categoryCount = sortedProducts.filter(
          (product) => product.category?.id === data?.id
        ).length;

        const rowspan = categoryCount > 1 ? categoryCount : 1;

        if (
          index === 0 ||
          record.category?.id !== sortedProducts[index - 1].category?.id
        ) {
          return {
            children: (
              <Text className={styles.categoryText}>
                {data ? data.name : 'Без категории'}
              </Text>
            ),
            props: {
              rowSpan: rowspan,
            },
          };
        }

        return {
          children: '',
          props: {
            rowSpan: 0,
          },
        };
      },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_, record) => (
        <DraggableItem
          item={record}
          collection={products.result}
          onDropAction={handleReorder}
          allowDropOn={dropOnSameCategory}
        >
          <span>{record.name}</span>
        </DraggableItem>
      ),
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      render: (data, record) => (
        <div className={styles.price}>
          <Text>{data}</Text>
          <Text className={styles.price__emoji}>
            {currencyIcon[record.currency.name]}
          </Text>
        </div>
      ),
    },
    {
      title: 'Доступен к заказу',
      dataIndex: 'isAvailable',
      key: 'isAvailable',
      width: 120,
      render: (data) => (data ? 'да' : 'нет'),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (data) =>
        data ? (
          <Tooltip title={data} placement="topLeft">
            <div className={styles.description}>{data}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Фото',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 80,
      render: (data: string[], record) => (
        <>
          <Button
            onClick={() => setImagePreviewOpen(record.id)}
            disabled={!data.length}
            size="large"
            icon={<FileImageOutlined />}
          />
          <Modal
            open={imagePreviewOpen === record.id}
            afterOpenChange={(open) => {
              if (open) {
                setImagePreviewOpen(record.id);
              } else {
                setImagePreviewOpen('');
              }
            }}
            onCancel={() => {
              setImagePreviewOpen('');
            }}
            footer={(_, { CancelBtn }) => <CancelBtn />}
          >
            <Image.PreviewGroup>
              <Space wrap>
                {data.map((img, idx) => (
                  <Image key={img + idx} width={200} src={img} />
                ))}
              </Space>
            </Image.PreviewGroup>
          </Modal>
        </>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      width: 150,
      render: (data) => (
        <Space size="small">
          <Tooltip title="Редактирование">
            <Button
              onClick={(e) => {
                e.preventDefault();

                handleEdit(data);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Удаление">
            <Popconfirm
              cancelText="Отменить"
              title="Вы уверены?"
              onConfirm={(e) => {
                e?.preventDefault();

                handleDelete(data.id);
              }}
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageLayout
        title="Магазин"
        actions={[
          <Button
            onClick={() => setProductCreateFormIsOpen(true)}
            icon={<PlusOutlined />}
            type="primary"
          >
            Новый товар
          </Button>,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <Table
              rowKey="id"
              bordered
              pagination={false}
              columns={columns}
              dataSource={sortedProducts}
              scroll={{ y: tableHeight, x: 'max-content' }}
              components={{
                header: {
                  wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                    <thead {...props} ref={tableHeadRef} />
                  ),
                },
              }}
            />
          </DndProvider>
        </div>
      </PageLayout>
      <ProductCreateForm
        open={productCreateFormIsOpen}
        currencies={currencies}
        categories={categories.result}
        onSubmit={handleSubmit}
        initialValue={editValue}
        onCancel={() => {
          setProductCreateFormIsOpen(false);
          setEditValue(null);
        }}
      />
    </>
  );
};
