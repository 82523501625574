import { TNotificationMessages } from 'api/types/common';

export const adminUsersMessages: TNotificationMessages = {
  '/admin-users': (action) => `Admin ${action}`,
  '/admin-users/hr': (action) => `HR ${action}`,
  '/admin-users/admins/:id': (action) => `Admin ${action}`,
  '/admin-users/hr/:id': (action) => `HR ${action}`,
  '/admin-users/rating/test': () => 'Test rating is send',
  '/admin-users/rating': () => 'Rating send to all users',
};
