import { orderStatusColor, orderStatusTranslations } from 'constants/orders';

import { useMemo, useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Space, Table, Tag, Image } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import { IOrder, IOrderHistory } from 'api/types/orders';
import { DataTable } from 'components/data-table';
import { format } from 'date-fns';
import { useAdaptiveTableHeight } from 'hooks/useAdaptiveTableHeight';

import { orderTableRows, productTableRows, userTableRows } from './rows';

import styles from './order.module.scss';

export const OrderPage = () => {
  const navigate = useNavigate();
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const { tableHeadHeight } = useAdaptiveTableHeight(null, tableHeadRef);

  const { order } = useLoaderData() as {
    order: IOrder;
  };

  const normalizedOrderStatusHistory = useMemo(
    () =>
      order.statusHistory
        .filter((history) => history.newStatus !== history.oldStatus)
        .sort(
          (a, b) =>
            new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime()
        ),
    [order]
  );

  const handleRedirectToPreviousPage = () => navigate(-1);

  const orderColumns: ColumnsType<IOrderHistory> = [
    {
      title: 'Дата',
      dataIndex: 'changedAt',
      key: 'changedAt',
      render: (_, record) => format(new Date(record.changedAt), 'dd.MM.yyyy'),
    },
    {
      title: 'Время',
      dataIndex: 'changedAt',
      key: 'changedAt',
      render: (_, record) => format(new Date(record.changedAt), 'HH:mm'),
    },
    {
      title: 'Статус',
      dataIndex: 'newStatus',
      key: 'newStatus',
      render: (_, record) => (
        <div>
          <Tag color={orderStatusColor[record.oldStatus]}>
            {orderStatusTranslations[record.oldStatus]}
          </Tag>
          <span style={{ marginRight: 8 }}>-&gt;&nbsp;</span>
          <Tag color={orderStatusColor[record.newStatus]}>
            {orderStatusTranslations[record.newStatus]}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Отправитель',
      dataIndex: 'changedUser',
      key: 'changedUser',
      render: (_, record) => record.changedUser.email,
    },
  ];

  return (
    <div className={styles.order}>
      <div className={styles.header}>
        <Button
          onClick={handleRedirectToPreviousPage}
          icon={<ArrowLeftOutlined style={{ color: 'gray' }} />}
        />
        <Title className={styles.heading}>Заказ номер: {order.id}</Title>
      </div>
      <div className={styles.body}>
        <Card className={styles.content} title="Информация">
          <DataTable rows={orderTableRows} value={order} />
        </Card>
        <Card className={styles.content} title="Заказчик">
          <DataTable rows={userTableRows} value={order.user} />
        </Card>
        <Card className={styles.content} title="Товар">
          <DataTable rows={productTableRows} value={order.product} />
        </Card>
        {order.product.imageUrl?.length ? (
          <Card className={styles.content} title="Фото">
            <Image.PreviewGroup>
              <Space>
                {order.product.imageUrl.map((img, idx) => (
                  <Image key={img + idx} width={100} src={img} />
                ))}
              </Space>
            </Image.PreviewGroup>
          </Card>
        ) : null}
        <Card
          className={styles.content}
          title="История изменений статуса заказа"
        >
          <Table
            locale={{
              emptyText: (
                <Empty description="Этот заказ еще никто не изменял" />
              ),
            }}
            className={styles.table}
            rowKey="id"
            bordered
            pagination={false}
            columns={orderColumns}
            dataSource={normalizedOrderStatusHistory}
            rowClassName={styles.tableRow}
            scroll={{ y: 450 - tableHeadHeight, x: 'max-content' }}
            components={{
              header: {
                wrapper: (props: any) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
          />
        </Card>
      </div>
    </div>
  );
};
