import { FC, createElement, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  TeamOutlined,
  SmileOutlined,
  TransactionOutlined,
  GroupOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
  TableOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { NavigationItemPathType } from 'api/types/common';
import { ERole } from 'api/types/enums';
import { useApp } from 'lib/app';
import { useRouteWrapper } from 'lib/role-wrapper';

const { Content, Sider } = Layout;

export const MainLayout: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useApp();

  const [collapsed, setCollapsed] = useState(false);

  const [current, setCurrent] = useState<NavigationItemPathType>(
    () => location.pathname.match(/^\/([^/]+)/)?.[1] as NavigationItemPathType
  );

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (current === key && !location.pathname.match(/^\/[^/]+\/([^/]+)/)?.[1])
      return;

    if (key) {
      setCurrent(key as NavigationItemPathType);
      navigate(key);
    }
  };

  const adminMenuItems: MenuProps['items'] = [
    useRouteWrapper([ERole.hr], {
      icon: createElement(SmileOutlined),
      key: 'admins',
      label: 'Администраторы',
    }),
    {
      icon: createElement(TeamOutlined),
      key: 'users',
      label: 'Пользователи',
    },
    useRouteWrapper([ERole.hr], {
      icon: createElement(TransactionOutlined),
      key: 'transactions',
      label: 'Транзакции',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(UnorderedListOutlined),
      key: 'orders',
      label: 'Заказы',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(ShoppingCartOutlined),
      key: 'shop',
      label: 'Магазин',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(GroupOutlined),
      key: 'categories',
      label: 'Категории',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(MessageOutlined),
      key: 'messages',
      label: 'Справочник',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(SendOutlined),
      key: 'newsletter',
      label: 'Рассылка',
    }),
    useRouteWrapper([ERole.hr], {
      icon: createElement(TableOutlined),
      key: 'ratings',
      label: 'Рейтинг спасибо',
    }),
  ];

  const renderMenu = () => {
    if (!auth.user) return;

    return (
      <Menu
        onClick={handleMenuClick}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[current]}
        selectedKeys={[current]}
        items={adminMenuItems}
      />
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        style={{ position: 'fixed', height: '100vh' }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {renderMenu()}
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: '.2s',
        }}
      >
        <Content style={{ padding: '24px 16px', overflow: 'hidden' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
