import { ID, IDataResponse } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getCurrencies = (): Promise<
  AxiosResponse<IDataResponse<ICurrency[]>>
> => $host.get('/admin/currencies');

export const postCurrency = (
  values: Pick<ICurrency, 'name'>
): Promise<AxiosResponse<IDataResponse<ICurrency>>> =>
  $host.post('/admin/currencies', { ...values });

export const updateCurrency = ({
  id,
  name,
}: ICurrency): Promise<AxiosResponse<IDataResponse<ICurrency>>> =>
  $host.patch(`/admin/currencies/${id}`, { name });

export const deleteCurrency = (
  id: ID
): Promise<AxiosResponse<IDataResponse<ICurrency[]>>> =>
  $host.delete(`/admin/currencies/${id}`);
