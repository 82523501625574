import { IDataResponse } from 'api/types/common';
import { IMessage } from 'api/types/messages';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getMessages = (): Promise<
  AxiosResponse<IDataResponse<IMessage[]>>
> => $host.get('/admin/messages');

export const bulkUpdateMessages = (
  messages: IMessage[]
): Promise<AxiosResponse<IDataResponse<IMessage[]>>> =>
  $host.patch('/admin/messages', {
    messages,
  });
