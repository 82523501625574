import { LoaderFunctionArgs } from 'react-router-dom';

import { getCurrencies } from 'api/currencies';
import { AnyType, ID } from 'api/types/common';
import { ERole } from 'api/types/enums';
import { getUserById, getUserOrders, getUserTransactions } from 'api/users';
import { IAuth } from 'lib/app';

export const loaders = ({ params, request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: `user/${params.id}`,
    fn: () => getUserById(params.id as ID),
  },
  {
    name: 'currencies',
    fn: getCurrencies,
    shouldBeRequested: (auth?: IAuth) => !auth?.user?.is(ERole.hr),
  },
  {
    name: `userOrders/${params.id}`,
    fn: () => getUserOrders(params.id as ID),
    shouldBeRequested: (auth?: IAuth) => !auth?.user?.is(ERole.hr),
  },
  {
    name: () => {
      const currencyId = new URL(request.url).searchParams.get('currencyId');
      const type = new URL(request.url).searchParams.get('type');

      return `transactions/${params.id}/${currencyId}/${type}`;
    },
    fn: () => {
      const currencyId = new URL(request.url).searchParams.get('currencyId');
      const type = new URL(request.url).searchParams.get('type');

      return getUserTransactions(
        currencyId,
        type === 'all' ? null : type,
        params.id as ID
      );
    },
    shouldBeRequested: (auth?: IAuth) => !auth?.user?.is(ERole.hr),
  },
];
