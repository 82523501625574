import { Button, Form, Input, Flex, Popconfirm } from 'antd';
import { IBroadcastPayload } from 'api/types/user';
import { MarkdownTip } from 'components/markdown-tip';
import { useSubmit } from 'hooks/useSubmit';
import { PageLayout } from 'layouts/page';

export const NewsletterPage = () => {
  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  const { submit: submitTest, loading: loadingTest } = useSubmit();
  const { submit: submitAllUsers, loading: loadingAllUsers } = useSubmit();

  const sendTestNewsletter = (submittedValues: IBroadcastPayload) => {
    submitTest({
      entity: 'testNewsletter',
      data: { ...submittedValues },
      queryKeys: [],
    });
  };

  const sendAllUsersNewsletter = (submittedValues: IBroadcastPayload) => {
    submitAllUsers({
      entity: 'sendNewsletter',
      data: { ...submittedValues },
      queryKeys: [],
    });
  };

  const handleSubmit = (fn: (values: IBroadcastPayload) => void) => () => {
    form
      .validateFields()
      .then(fn)
      .catch(() => {
        console.log('FAILED TO SEND :>> ');
      });
  };

  const isButtonsDisabled =
    loadingAllUsers || loadingTest || !values?.message?.trim().length;

  return (
    <PageLayout title="Рассылка">
      <Form form={form} layout="vertical" name="newsletter-create">
        <Flex gap={8} vertical>
          <Form.Item
            name="message"
            label="Сообщение"
            rules={[
              {
                max: 4096,
                message: 'Максимальное количество символов - 4096',
              },
            ]}
          >
            <Input.TextArea style={{ resize: 'none' }} rows={6} />
          </Form.Item>
          <MarkdownTip />
          <Flex gap={8}>
            <Button
              type="primary"
              onClick={handleSubmit(sendTestNewsletter)}
              loading={loadingTest}
              disabled={isButtonsDisabled}
            >
              Тестовое сообщение
            </Button>
            <Popconfirm
              cancelText="Отменить"
              title="Отправить сообщение всем пользователям?"
              onConfirm={handleSubmit(sendAllUsersNewsletter)}
            >
              <Button
                type="primary"
                loading={loadingAllUsers}
                disabled={isButtonsDisabled}
              >
                Отправить сообщение
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
      </Form>
    </PageLayout>
  );
};
