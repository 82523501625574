import { IBroadcastPayload } from 'api/types/user';
import { usersBroadcast, usersBroadcastTest } from 'api/users';

export const actions = {
  testNewsletter: async (payload: IBroadcastPayload) => {
    await usersBroadcastTest(payload);
  },
  sendNewsletter: async (payload: IBroadcastPayload) => {
    await usersBroadcast(payload);
  },
};
