import { Card, Flex } from 'antd';

import exampleImage from './image.png';

import styles from './markdown-tip.module.scss';

export const MarkdownTip = () => (
  <Card rootClassName={styles.markdown}>
    <Flex gap={24} wrap="wrap">
      <div className={styles.markdown__text}>
        <div className={styles.markdown__line}>_курсив_</div>
        <div className={styles.markdown__line}>__подчеркнутый текст__</div>
        <div className={styles.markdown__line}>
          [текст ссылки](https://ссылка)
        </div>
        <div className={styles.markdown__line}>*жирный*</div>
        <div className={styles.markdown__line}>||спойлер||</div>
        <div className={styles.markdown__line}>`код`</div>
        <div className={styles.markdown__line}>```блок кода```</div>
      </div>
      <div className={styles.markdown__example}>
        <img src={exampleImage} alt="markdown example" />
      </div>
    </Flex>
  </Card>
);
