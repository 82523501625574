import { InternalAxiosRequestConfig } from 'axios';

import { adminUsersMessages } from './admin-users/messages';
import { categoriesMessages } from './categories/messages';
import { currenciesMessages } from './currencies/messages';
import { messagesMessages } from './messages/messages';
import { orderMessages } from './orders/messages';
import { productMessages } from './products/messages';
import { transactionsMessages } from './transactions/messages';
import { TNotificationMessages } from './types/common';
import { usersMessages } from './users/messages';

const messages: TNotificationMessages = {
  ...adminUsersMessages,
  ...categoriesMessages,
  ...currenciesMessages,
  ...messagesMessages,
  ...orderMessages,
  ...productMessages,
  ...transactionsMessages,
  ...usersMessages,
};

export const getNotificationText = (
  config: InternalAxiosRequestConfig<any>
) => {
  const message = Object.keys(messages).find((message) =>
    new RegExp(`^${message.replace(':id', '\\d+')}$`).test(config?.url ?? '')
  );

  if (message && config.method !== 'get') {
    return messages[message](action(config.method) ?? '');
  }

  return null;
};

const action = (method?: string) => {
  if (method === 'post') {
    return `created`;
  }

  if (method === 'patch' || method === 'put') {
    return `updated`;
  }

  if (method === 'delete') {
    return `deleted`;
  }
};
