import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Button, Form, Input } from 'antd';
import { EMessageCode, IMessage, IMessageForm } from 'api/types/messages';
import { useSubmit } from 'hooks/useSubmit';
import { PageLayout } from 'layouts/page';

import styles from './messages.module.scss';

export function serializeMessages(messages: IMessage[]): Partial<IMessageForm> {
  return (
    messages.reduce(
      (acc, message) => ({
        ...acc,
        [message.code]: message.text,
      }),
      {}
    ) || {}
  );
}

export function deserializeMessages(
  messages: IMessageForm
): Omit<IMessage, 'id'>[] {
  return Object.entries(messages).map(([key, value]) => ({
    code: key as EMessageCode,
    text: value,
  }));
}

const MESSAGES = [
  {
    name: 'GREETINGS',
    label: 'Приветственное сообщение',
  },
  {
    name: 'REGISTRATION_SUCCESS',
    label: 'Успешная регистрация',
  },
  {
    name: 'USER_ALREADY_EXISTS',
    label: 'Пользователь существует',
  },
  {
    name: 'ERROR',
    label: 'Ошибка',
  },
  {
    name: 'UNKNOWN_COMMAND',
    label: 'Неизвестная команда',
  },
  {
    name: 'EMAIL_VALIDATION_ERROR',
    label: 'Валидационная ошибка email',
  },
  {
    name: 'HELP',
    label: 'Help',
  },
  {
    name: 'HELP_NEW_USER',
    label: 'Help для не зарегестрированного юзера',
  },
  {
    name: 'RATING_HEADER',
    label: 'Шапка рейтинга',
  },
];

export const MessagesPage = () => {
  const [form] = Form.useForm();

  const { messages } = useLoaderData() as {
    messages: IMessage[];
  };

  const { submit, loading } = useSubmit();

  const submitMessages = () => {
    form
      .validateFields()
      .then((values: IMessageForm) => {
        submit({
          entity: 'saveMessages',
          data: deserializeMessages(values),
          queryKeys: ['messages'],
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  useEffect(() => {
    form.setFieldsValue(serializeMessages(messages));
  }, [form, messages]);

  return (
    <PageLayout title="Справочник">
      <div className={styles.form}>
        <Form form={form} layout="vertical" name="configure-messages">
          {MESSAGES.map((message) => (
            <Form.Item
              key={message.name}
              name={message.name}
              label={message.label}
            >
              <Input.TextArea style={{ resize: 'none' }} rows={6} />
            </Form.Item>
          ))}
          <Button
            type="primary"
            className={styles.form_submit}
            onClick={submitMessages}
            loading={loading}
          >
            Сохранить
          </Button>
        </Form>
      </div>
    </PageLayout>
  );
};
