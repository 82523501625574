import { Button, DatePicker, Flex, Form, Popconfirm } from 'antd';
import { useSubmit } from 'hooks/useSubmit';
import { PageLayout } from 'layouts/page';

const { RangePicker } = DatePicker;

const disabledDate = (current: unknown) =>
  new Date(current as Date).getTime() >= Date.now();

export const RatingsPage = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const { loading: generationLoading, submit: generationSubmit } = useSubmit();
  const { loading: sendLoading, submit: sendSubmit } = useSubmit();

  const generateRatings = () => {
    form
      .validateFields()
      .then((values) => {
        generationSubmit({
          entity: 'generate',
          data: {
            from: values.date[0],
            to: values.date[1],
            limit: 10,
          },
        });
      })
      .catch(() => {
        console.log('GENERATION OF RATING FAILED');
      });
  };

  const sendRatings = () => {
    form
      .validateFields()
      .then((values) => {
        sendSubmit({
          entity: 'send',
          data: {
            from: values.date[0],
            to: values.date[1],
            limit: 10,
          },
        });
      })
      .catch(() => {
        console.log('SEND RATING FAILED');
      });
  };

  return (
    <PageLayout title="Рейтинг спасибо">
      <Form
        form={form}
        layout="vertical"
        name="admin-create"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="date"
          label="Период"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите период',
            },
          ]}
        >
          <RangePicker disabledDate={disabledDate} />
        </Form.Item>
        <Flex gap={8}>
          <Button
            type="primary"
            disabled={!values?.date?.length || generationLoading || sendLoading}
            loading={generationLoading}
            onClick={generateRatings}
          >
            Отправить тестовое
          </Button>
          <Popconfirm
            title="Вы уверены?"
            cancelText="Отменить"
            onConfirm={(e) => {
              e?.preventDefault();

              sendRatings();
            }}
          >
            <Button
              type="primary"
              disabled={
                !values?.date?.length || generationLoading || sendLoading
              }
              loading={sendLoading}
            >
              Отправить в General
            </Button>
          </Popconfirm>
        </Flex>
      </Form>
    </PageLayout>
  );
};
