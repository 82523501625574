import { currencyIcon, currencyTranslations } from 'constants/currencies';
import { TRANSACTION_DETAILS } from 'constants/transactions';

import { FC, useMemo, useRef } from 'react';
import { URLSearchParamsInit, useNavigation } from 'react-router-dom';

import { Button, Radio, Space, Tabs, TabsProps, Tag, Tooltip } from 'antd';
import Table, { ColumnsType, TableProps } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import { ITypeFilter } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { ETransactionType } from 'api/types/enums';
import { ITransaction } from 'api/types/transactions';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from 'components/skeleton-table';
import { format } from 'date-fns';
import { useTableHeight } from 'hooks/useTableHeight';

import styles from './transaction-table.module.scss';

interface TransactionTableProps {
  transactions: ITransaction[];
  searchParams: URLSearchParams;
  activeTab: string;
  typeFilter: ITypeFilter;
  currencies: ICurrency[];
  onSearchParams: (params: URLSearchParamsInit) => void;
  onTypeFilter: (typeFilter: string | null) => void;
  onActiveTab: (activeTab: string) => void;
  scrollY?: number;
}

export const TransactionTable: FC<TransactionTableProps> = ({
  transactions,
  activeTab,
  searchParams,
  onSearchParams,
  onTypeFilter,
  typeFilter,
  scrollY,
  onActiveTab,
  currencies,
}) => {
  const { state } = useNavigation();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const loading = state === 'loading';

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const currencyTabs: TabsProps['items'] = useMemo(
    () =>
      currencies
        .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
        .map(({ name, id }) => ({
          key: id.toString(),
          label: currencyTranslations[name],
          icon: <span>{currencyIcon[name]}</span>,
        })),
    [currencies]
  );

  const columns: ColumnsType<ITransaction> = [
    {
      title: 'Тип транзакции',
      key: 'type',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <Space direction="vertical" style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => setSelectedKeys([e.target.value])}
            value={selectedKeys[0]}
          >
            <Space direction="vertical">
              {Array.from(Object.entries(ETransactionType)).map((level) => (
                <Radio value={level[0]}>{level[1]}</Radio>
              ))}
            </Space>
          </Radio.Group>
          <Space>
            <Button
              size="small"
              onClick={() => clearFilters?.()}
              disabled={!selectedKeys[0]}
            >
              Сбросить
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => confirm()}
              disabled={
                (selectedKeys[0] || null) === typeFilter[activeTab].filter
              }
            >
              Применить
            </Button>
          </Space>
        </Space>
      ),
      filteredValue: typeFilter[activeTab].filter
        ? [typeFilter[activeTab].filter as string]
        : null,
      width: 150,
      render: (_, record) => (record.amount > 0 ? 'Начисление' : 'Списание'),
    },
    {
      title: 'Количество',
      dataIndex: 'amount',
      key: 'amount',
      width: 130,
      render: (_, record) => {
        const color = record.amount > 0 ? 'green' : 'volcano';

        return (
          <Tag color={color} key={record.id}>
            {record.amount > 0 ? '+' : ''}
            {record.amount}
          </Tag>
        );
      },
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 90,
      render: (_, record) => format(new Date(record.createdAt), 'dd.MM.yyyy'),
    },
    {
      title: 'Время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (_, record) => format(new Date(record.createdAt), 'HH:mm'),
    },
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: (_, record) => record.user.fullName || '-',
    },
    {
      title: 'Отправитель',
      dataIndex: 'createdByUser',
      key: 'createdByUser',
      width: 150,
      render: (_, record) => {
        if (record.createdByUser) {
          return record.createdByUser.email;
        }

        if (record.tipsTransactions) {
          return record.tipsTransactions.tips.sender.fullName;
        }

        if (Object.keys(TRANSACTION_DETAILS).includes(record.details)) {
          return 'Система';
        }

        return '-';
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'details',
      key: 'details',
      width: 300,
      render: (value, record) => {
        if (Object.keys(TRANSACTION_DETAILS).includes(value)) {
          switch (value) {
            case TRANSACTION_DETAILS.TRANSACTION_DETAILS_ORDER_NEW:
              return `Списание за заказ №${record.orderTransaction.orderId}`;
            case TRANSACTION_DETAILS.TRANSACTION_DETAILS_ORDER_REFUND:
              return `Возврат за заказ №${record.orderTransaction.orderId}`;
            case TRANSACTION_DETAILS.EVERY_YEAR_PAYMENTS:
              return `Ежегодная выплата за выслугу лет`;
            case TRANSACTION_DETAILS.MONTHLY_BURNING:
              return `Ежемесячное обнуление "Спасибо"`;
            case TRANSACTION_DETAILS.PLANNING_REPLENISHMENT:
              return `Пополнение "Спасибо"`;
            case TRANSACTION_DETAILS.CASHBACK:
              return `Кэшбек за спасибо для ${record.tipsTransactions.tips.receiver.fullName}`;
            default:
              return '-';
          }
        }

        return (
          <Tooltip title={value} placement="topLeft">
            <div className={styles.comment}>{value}</div>
          </Tooltip>
        );
      },
    },
  ];

  const thankColumns: ColumnsType<ITransaction> = [
    {
      title: 'Тип транзакции',
      key: 'type',
      filters: Array.from(Object.entries(ETransactionType)).map((level) => ({
        text: level[1],
        value: level[0],
      })),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <Space direction="vertical" style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => setSelectedKeys([e.target.value])}
            value={selectedKeys[0]}
          >
            <Space direction="vertical">
              {Array.from(Object.entries(ETransactionType)).map((level) => (
                <Radio value={level[0]}>{level[1]}</Radio>
              ))}
            </Space>
          </Radio.Group>
          <Space>
            <Button
              size="small"
              onClick={() => clearFilters?.()}
              disabled={!selectedKeys[0]}
            >
              Сбросить
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => confirm()}
              disabled={
                (selectedKeys[0] || null) === typeFilter[activeTab].filter
              }
            >
              Применить
            </Button>
          </Space>
        </Space>
      ),
      filteredValue: typeFilter[activeTab].filter
        ? [typeFilter[activeTab].filter as string]
        : null,
      width: 150,
      render: (_, record) => (record.amount > 0 ? 'Начисление' : 'Списание'),
    },
    {
      title: 'Количество',
      dataIndex: 'amount',
      key: 'amount',
      width: 130,
      render: (_, record) => {
        const color = record.amount > 0 ? 'green' : 'volcano';

        return (
          <Tag color={color} key={record.id}>
            {record.amount > 0 ? '+' : ''}
            {record.amount}
          </Tag>
        );
      },
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 90,
      render: (_, record) => format(new Date(record.createdAt), 'dd.MM.yyyy'),
    },
    {
      title: 'Время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (_, record) => format(new Date(record.createdAt), 'HH:mm'),
    },
    {
      title: 'Кто отправил',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: (_, record) => {
        if (record.createdByUser) {
          return record.createdByUser.email;
        }

        if (record.tipsTransactions) {
          return record.tipsTransactions.tips.sender.fullName;
        }

        if (Object.keys(TRANSACTION_DETAILS).includes(record.details)) {
          return 'Система';
        }

        return '-';
      },
    },
    {
      title: 'Кому отправил',
      dataIndex: 'tipsTransactions',
      key: 'tipsTransactions',
      width: 150,
      render: (_, record) => {
        if (record.tipsTransactions) {
          return record.tipsTransactions?.tips.receiver.fullName;
        }

        return record.user.fullName;
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'details',
      key: 'details',
      width: 300,
      render: (value) => {
        if (Object.keys(TRANSACTION_DETAILS).includes(value)) {
          switch (value) {
            case TRANSACTION_DETAILS.MONTHLY_BURNING:
              return `Ежемесячное обнуление "Спасибо"`;
            case TRANSACTION_DETAILS.PLANNING_REPLENISHMENT:
              return `Пополнение "Спасибо"`;
            default:
              return '-';
          }
        }

        return (
          <Tooltip title={value} placement="topLeft">
            <div className={styles.comment}>{value}</div>
          </Tooltip>
        );
      },
    },
  ];

  const handleTabsChange = (key: string) => {
    onActiveTab(key);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('currencyId', key);

    newSearchParams.set(
      'type',
      typeFilter[key].filter ? (typeFilter[key].filter as string) : 'all'
    );

    onSearchParams(newSearchParams);
  };

  const handleFiltersChange: TableProps<ITransaction>['onChange'] = (
    _,
    filters
  ) => {
    const newSearchParams = new URLSearchParams(searchParams);

    onTypeFilter(filters.type ? (filters.type[0] as string) : null);

    newSearchParams.set(
      'type',
      filters.type ? (filters.type[0] as string) : 'all'
    );

    onSearchParams(newSearchParams);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabsChange}
        indicatorSize={(origin) => origin - 16}
      >
        {currencyTabs.map(({ key, icon, label }) => (
          <TabPane
            tab={
              <Space size="small" align="start">
                {icon}
                {label}
              </Space>
            }
            key={key}
          />
        ))}
      </Tabs>
      <div className={styles.tableContent} ref={tableContainerRef}>
        <SkeletonTable
          loading={loading}
          columns={
            (activeTab === '3'
              ? thankColumns
              : columns) as SkeletonTableColumnsType[]
          }
        >
          <Table
            className={styles.table}
            bordered
            rowKey="id"
            pagination={false}
            columns={activeTab === '3' ? thankColumns : columns}
            dataSource={transactions}
            onChange={handleFiltersChange}
            scroll={{
              y: scrollY ? scrollY : tableHeight,
              x: 'max-content',
            }}
            components={{
              header: {
                wrapper: (props: any) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
          />
        </SkeletonTable>
      </div>
    </>
  );
};
