import { HTMLAttributes, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IAdminUser } from 'api/types/admin-users';
import { ERole, TRole } from 'api/types/enums';
import { AdminCreateForm } from 'components/forms/admin/admin-create';
import { format } from 'date-fns';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';
import { useApp } from 'lib/app';

import styles from './admins.module.scss';

export const AdminsPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [adminCreateFormIsOpen, setAdminCreateFormIsOpen] = useState(false);

  const { auth } = useApp();

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const { submit } = useSubmit();

  const { admins } = useLoaderData() as {
    admins: IAdminUser[];
  };

  const handleDelete =
    ({ role, id }: Pick<IAdminUser, 'id' | 'role'>) =>
    () => {
      submit({
        entity: role === 'hr' ? 'deleteHr' : 'deleteAdmin',
        data: { id },
        queryKeys: ['admins'],
      });
    };

  const columns: ColumnsType<IAdminUser> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.cell,
      render: (data) => <div style={{ minWidth: '60px' }}>{data}</div>,
    },
    {
      title: 'Google Id',
      dataIndex: 'googleId',
      key: 'googleId',
      className: styles.cell,
      render: (data) => <div style={{ minWidth: '90px' }}>{data}</div>,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      className: styles.cell,
      render: (data: TRole) => (
        <div style={{ minWidth: '70px' }}>{ERole[data]}</div>
      ),
    },
    {
      title: 'Создан',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: styles.cell,
      render: (data) => (
        <div style={{ minWidth: '70px' }}>
          {format(new Date(data), 'dd.MM.yyyy')}
        </div>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      width: '10%',
      className: styles.actions,
      render: (record) => {
        if (
          auth.user?.role === 'owner' ||
          (auth.user?.role === 'admin' && record.role === 'hr')
        ) {
          return (
            <Space size="middle">
              <Popconfirm
                cancelText="Отменить"
                title="Вы уверенны что хотите удалить пользователя?"
                onConfirm={handleDelete({
                  role: record.role,
                  id: record.id,
                })}
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          );
        }

        return null;
      },
    },
  ];

  return (
    <>
      <PageLayout
        title="Администраторы"
        actions={[
          <Button
            onClick={() => setAdminCreateFormIsOpen(true)}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Новый администратор
          </Button>,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <Table
            className={styles.table}
            rowKey="id"
            bordered
            pagination={false}
            columns={columns}
            dataSource={admins}
            scroll={{ y: tableHeight, x: 'max-content' }}
            components={{
              header: {
                wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
          />
        </div>
      </PageLayout>
      <AdminCreateForm
        open={adminCreateFormIsOpen}
        onCancel={() => setAdminCreateFormIsOpen(false)}
      />
    </>
  );
};
