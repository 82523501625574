import { cancelOrder, updateOrder } from 'api/orders';
import { postTransaction } from 'api/transactions';
import { ID } from 'api/types/common';
import { IOrder } from 'api/types/orders';
import { ICreateTransaction } from 'api/types/transactions';
import { IUser } from 'api/types/user';
import { banUser, updateUser } from 'api/users';

export const actions = {
  createTransaction: async (data: ICreateTransaction) => {
    await postTransaction(data);
  },
  updateUser: async (data: {
    values: Pick<
      IUser,
      | 'telegramId'
      | 'status'
      | 'fullName'
      | 'city'
      | 'employmentDate'
      | 'birthDate'
    >;
    id: ID;
  }) => {
    await updateUser(data.values, data.id);
  },
  updateOrder: async (data: { userId: ID } & Pick<IOrder, 'status' | 'id'>) => {
    await updateOrder(data);
  },
  cancelOrder: async (data: { description: string; id: ID }) => {
    await cancelOrder(data);
  },
  banUser: async (data: Pick<IUser, 'id'>) => {
    await banUser(data);
  },
};
