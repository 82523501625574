import { currencyIcon } from 'constants/currencies';
import { orderStatusColor, orderStatusTranslations } from 'constants/orders';

import { FC, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Tag, Tooltip } from 'antd';
import Table, { ColumnsType, TableProps } from 'antd/es/table';
import { ID } from 'api/types/common';
import { EOrderStatus } from 'api/types/enums';
import { IOrder } from 'api/types/orders';
import { IProduct } from 'api/types/products';
import { ControlledSelect } from 'components/controlled-select';
import { OrderCancelForm } from 'components/forms/order/order-cancel';
import { OrdersFilterConfirm } from 'components/orders-filter-confirm';
import { format } from 'date-fns';
import { useAdaptiveTableHeight } from 'hooks/useAdaptiveTableHeight';
import { useSubmit } from 'hooks/useSubmit';
import { makeOptionsFromEnum } from 'utils';

import styles from '../user.module.scss';

interface IUserOrdersProps {
  orders: IOrder[];
  userId: ID;
}

export const UserOrders: FC<IUserOrdersProps> = ({ orders, userId }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [ordersStatusFilter, setOrdersStatusFilter] = useState<string[] | null>(
    null
  );

  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [cancelOrderId, setCancelOrderId] = useState<ID | null>(null);

  const { submit } = useSubmit(() => {
    setOpen(false);
  });

  const { tableHeadHeight } = useAdaptiveTableHeight(null, tableHeadRef);

  const filteredOrders = useMemo(
    () =>
      ordersStatusFilter
        ? orders.filter((order) => ordersStatusFilter.includes(order.status))
        : orders,
    [orders, ordersStatusFilter]
  );

  const handleRowClick = (id: ID) => navigate(`/orders/${id}`);

  const handleStatusEdit = (status: string, id: ID, userId: ID) => {
    if (status === EOrderStatus.CANCELED) {
      setOpen(true);
      setCancelOrderId(id);
    } else {
      submit({
        entity: 'updateOrder',
        data: { status, id, userId },
        queryKeys: [`userOrders/${userId}`],
      });
    }
  };

  const handleCancel = (values: any, entity: string, id: ID) => {
    submit({
      entity: entity,
      data: { ...values, id },
      queryKeys: [`userOrders/${userId}`],
    });

    setCancelOrderId(null);
    setOpen(false);
  };

  const handleFiltersChange: TableProps<IOrder>['onChange'] = (_, filters) =>
    setOrdersStatusFilter(filters.status ? (filters.status as string[]) : null);

  const orderColumns: ColumnsType<IOrder> = [
    {
      title: 'Номер заказа',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      render: (data) => <div style={{ minWidth: '120px' }}>{data}</div>,
    },
    {
      title: 'Товар',
      dataIndex: 'product',
      key: 'product',
      width: '20%',
      render: (data) => <div style={{ minWidth: '88px' }}>{data.name}</div>,
    },
    {
      title: 'Дата заказа',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      render: (data) => (
        <div style={{ minWidth: '100px' }}>
          {format(new Date(data), 'dd.MM.yyyy')}
        </div>
      ),
    },
    {
      title: 'Стоимость',
      dataIndex: 'product',
      key: 'product',
      width: '10%',
      render: (data: IProduct) => (
        <div style={{ minWidth: '100px' }}>
          {data.price} {currencyIcon[data.currency.name]}
        </div>
      ),
    },
    {
      title: 'Отправитель',
      dataIndex: 'statusHistory',
      key: 'statusHistory',
      width: 150,
      render: (_, record) =>
        record?.statusHistory[record?.statusHistory?.length - 1]?.changedUser
          ?.email || 'Система',
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      width: 300,
      render: (data) =>
        data ? (
          <Tooltip placement="topLeft" title={data}>
            <div className={styles.comment}>{data}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      filterDropdown: (filterDropdownProps) => (
        <OrdersFilterConfirm
          {...filterDropdownProps}
          ordersStatusFilter={ordersStatusFilter}
        />
      ),
      render: (data: EOrderStatus, record) => (
        <ControlledSelect
          disabled={data === EOrderStatus.CANCELED}
          value={data}
          className={styles.select}
          mode="multiple"
          tagRender={(props) => (
            <Tag color={orderStatusColor[data]}>{props.label}</Tag>
          )}
          defaultValue={EOrderStatus[data]}
          options={makeOptionsFromEnum(orderStatusTranslations)}
          onChange={(value) => {
            handleStatusEdit(value[1], record.id, record.userId);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Card className={styles.tableContainer} title="Заказы">
        <Table
          className={styles.table}
          rowKey="id"
          bordered
          pagination={false}
          columns={orderColumns}
          dataSource={filteredOrders}
          rowClassName={styles.tableRow}
          scroll={{ y: 450 - tableHeadHeight, x: 'max-content' }}
          onChange={handleFiltersChange}
          components={{
            header: {
              wrapper: (props: any) => <thead {...props} ref={tableHeadRef} />,
            },
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record.id),
          })}
        />
      </Card>
      <OrderCancelForm
        open={open}
        onSubmit={handleCancel}
        id={cancelOrderId}
        onCancel={() => {
          setOpen(false);
          setCancelOrderId(null);
        }}
      />
    </>
  );
};
