import { sendRatingToAll, sendTestRating } from 'api/admin-users';
import { IRatingPayload } from 'api/types/admin-users';

export const actions = {
  send: async (data: IRatingPayload) => {
    await sendRatingToAll(data);
  },
  generate: async (data: IRatingPayload) => {
    await sendTestRating(data);
  },
};
