import { FC, useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDebounce } from 'hooks/useDebounce';

interface ISearchInputProps {
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
}

export const SearchInput: FC<ISearchInputProps> = ({
  placeholder,
  className,
  onChange,
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchValue = useDebounce<string>(searchInputValue, 1500);

  useEffect(
    () => onChange?.(debouncedSearchValue),
    [debouncedSearchValue, onChange]
  );

  return (
    <Input
      className={className}
      size="middle"
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      value={searchInputValue}
      onChange={(e) => setSearchInputValue(e.currentTarget.value)}
    />
  );
};
