import { ETransactionDetails } from 'api/types/enums';

export const TRANSACTION_DETAILS: Record<ETransactionDetails, string> = {
  [ETransactionDetails.TRANSACTION_DETAILS_ORDER_NEW]:
    'TRANSACTION_DETAILS_ORDER_NEW',
  [ETransactionDetails.TRANSACTION_DETAILS_ORDER_REFUND]:
    'TRANSACTION_DETAILS_ORDER_REFUND',
  [ETransactionDetails.PLANNING_REPLENISHMENT]: 'PLANNING_REPLENISHMENT',
  [ETransactionDetails.MONTHLY_BURNING]: 'MONTHLY_BURNING',
  [ETransactionDetails.EVERY_YEAR_PAYMENTS]: 'EVERY_YEAR_PAYMENTS',
  [ETransactionDetails.CASHBACK]: 'CASHBACK',
};
